
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { ElButton, ElForm, ElNotification } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "uncheck",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Бүртгэлгүй этгээд /УБЕГ/");
    });
    const adduncheckregister = ref<InstanceType<typeof ElForm>>();
    const submitButton = ref<InstanceType<typeof ElButton>>();
    const submitHideButton = ref<InstanceType<typeof ElButton>>();
    const formSubmit = () => {
      adduncheckregister.value?.validate(async valid => {
        if (valid) submitHideButton.value?.$el.click();
        else return;
      });
    };

    return {
      adduncheckregister,
      submitButton,
      submitHideButton,
      formSubmit
    };
  },
  data() {
    return {
      page: 1,
      pageSize: 5,
      customerSearch: "",
      loadingTable: false,
      addbuttonloader: "off",
      tableData: [],
      modalform: {
        registerno: ""
      },
      modalformrules: {
        registerno: [
          {
            required: true,
            message: "Регистр хоосон байна.",
            trigger: "blur"
          },
          {
            min: 4,
            message: "Урт хамгийн багадаа 4-аас их байна.",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    this.getRegister();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
    },
    handleChange(value) {
      this.pageSize = value;
    },
    async addRegister() {
      if (this.modalform.registerno.length < 4) return;
      this.addbuttonloader = "on";
      await ApiService.post(`uncheck`, {
        params: {
          registerno: this.modalform.registerno
        }
      })
        .then(resp => {
          this.addbuttonloader = "off";
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.getRegister();
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.addbuttonloader = "off";
          Swal.fire({
            text: "Регистр нэмэхэд алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },
    async getRegister() {
      this.loadingTable = true;
      await ApiService.get(`uncheck`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            this.tableData = results.response.reverse().map(res => ({
              registerno: res.registerno.trim()
            }));
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    }
  }
});
